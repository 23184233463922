.ActivityHistory.hide {
    transform: translateY(100vh);
    transition: transform 0.5s;
    left: 0;
}

.ActivityHistory.show {
    transform: translateY(none);
    transition: transform 0.5s;
    left: 0;
}

.ActivityHistory {
    height: 100vh;
    width: 100%;
    background-color: white;
    position: fixed;
    top: 0;
    overflow: scroll;
    z-index: 999;

}

.ActivityHistory .day {
    font-weight: bold;
    padding: 0 5px;
    margin: 0 6px;
    width: 60px;
}

.ActivityHistory .title {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: right;
    align-items: right;
    position: fixed;
    
}

.ActivityHistory .ActivityTitle {
    position: relative;
    margin: 40px 10px 40px 10px;
    font-weight: 100;
    font-size: 2em;
    margin-top: 82px;
    /* border: thin solid lightgray;
    background-color: whitesmoke; */
    
}

.ActivityHistory .notes {
    list-style: none;
    padding: 0;
    margin-bottom: 150px;
    font-weight: 100;
}

.ActivityHistory .notes .note {
    display: flex;
    text-align: left;
    font-size: 0.8em;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 10px 10px 10px;
    border-top: 1px solid #F4F5F7;
    min-height: 80px;
    color: #212529;
}

.ActivityHistory .notes .note i {
    color: #5DC2A7;
}

.ActivityHistory .notes .note a {
    text-transform: uppercase;
}

.ActivityHistory .notes .note .text {
    max-width: 70%;
    margin-right: 20px;
    
}

.ActivityHistory .back i {
    font-size: 2.5em;
    margin-top: 52px;
    cursor: pointer;
}