.Profile {
    display: flex;

    color: #000;
    padding: 10px 0;
    flex-direction: column;
    align-items: center;
    margin-top: -42px;
    margin-bottom: 80px;
    

}

.Profile form {
    width: 98%;
    
}

.Profile .fullname {
    font-size: 1.4em;
    font-weight: bold;
    margin-bottom: 20px;
}


.Profile .avatar-thumb i {

    width: 120px;
    height: 120px;
    clip-path: circle(60px at center);
    font-size: 4.4em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4c3494;
    color: #fff;
    
}

.Profile .avatar-thumb {
    width: 150px;
    color: '#fff'
    
   
}

.Profile .avatar-thumb img {

    width: 150px;
    height: 150px;
    clip-path: circle(60px at center);
    object-fit: cover;
}

.Profile .p-button {
    border-radius: 30px;
}

.Profile input {
    text-align: center;
}

.Profile .p-dropdown-label {
    /* height: 100%; */
    margin: auto;
    margin-top: 13px;
    padding: 0;
}


