.App {
  text-align: center;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 576px) {
  .App {
    display: flex;
    justify-content: center;
  }
}
