.Home {
    margin-bottom: 80px;
    max-width: 500px;
}

.Home .top-panel {
    min-height: 100px;
    width: 100%;
    /* padding-top: 10px; */
    border-bottom: thin solid #dddddd;
    /* border-top: thin solid #dddddd; */
}

.Home .top-panel .top-panel-left {
   border-right: thin solid #dddddd;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding-top: 20px;
}

.Home .top-panel .top-panel-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    
}

.Home .top-panel .top-panel-left h1, 
.Home .top-panel .top-panel-right h1 {
    background: #F2F2F2;
  border-radius: 2em;
  -moz-border-radius: 2em;
  -webkit-border-radius: 2em;
  color: #080808;
  display: inline-block;
  /* font-weight: bold; */
  line-height: 1.2em;
  margin-right: 5px;
  text-align: center;
  min-width: 2em;
  padding: 0.4em; 
}

.Home .top-panel .top-panel-right h1 {
    background: #f2f2f2;
    color: #080808;
}

.Home .main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.Home .leaderboard {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 100%;
    margin: 0;
}



.Home .avatar-thumb {
    width: 50px;
    margin-right: 10px;
    
   
}

.Home .avatar-thumb img {

    width: 50px;
    height: 50px;
    clip-path: circle(25px at center);
    object-fit: cover;
}

.Home .points {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    font-size: 2em;
}