.AchievementDialog .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.AchievementDialog input {
    width: 100%;
    height: 20px;
    border: none;
    border-bottom: 1px solid #322e2e;
    border-radius: 0;
}

.AchievementDialog .formError {
    color: red;
    font-size: 10px;
    text-align: left;
    /* margin-left: 15px; */
}

.AchievementDialog .p-checkbox-box {
    
    height: 50px !important;
    width: 50px !important;
}

.AchievementDialog .p-checkbox-box.p-highlight {
    background-color: rgb(64, 66, 130) !important;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.AchievementDialog .p-checkbox-icon {
    font-size: 50px !important;
} 

.AchievementDialog .p-dialog-content {
    padding: 0
}