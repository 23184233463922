@import url('https://fonts.googleapis.com/css?family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans|Oswald&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.Placeholder {
    /* height: 100vh;
    max-width: 800px; */
    /* padding: 20px; */
    margin: auto;
    font-family: 'Open Sans', sans-serif;
    /* margin-bottom: 80px; */
}

.Placeholder .section {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* margin: 30px 0px; */
    padding-top: 20px;
    font-family: 'Poppins', sans-serif;
    
    
}

.Placeholder .section.text {
    padding: 20px;
}

.Placeholder .section .bold {
    font-weight: bold;
}

.Placeholder .section.text ul {
    margin-bottom: -65px;

}

.Placeholder .section.main .days {
    color: darkorange;
    font-weight: bolder;
}

.Placeholder .section.header {
    width: 100%;
    height: 20%;
    border-bottom-left-radius: 50% 20%;
    border-bottom-right-radius: 50% 20%;
    padding: 0;

}

.Placeholder .section.header img {
    width: 100%;
}

.Placeholder .section.logo img {
    /* padding: 30px; */
    /* background-color: yellow; */
    width: 100%;
    
}



.Placeholder .footer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: absolute;
    bottom: 0;
    width: 100%;
    /* padding-bottom: 20px; */
    
}
.Placeholder .footer .logo img {
    width: 200px;
    margin: 20px;
    /* background-color: red; */
}

.Placeholder .section.text {
    /* background-color: blue; */
    text-align: left;
    overflow: scroll;
    
}

.Placeholder h1, .Placeholder .intro-subtitle, .Placeholder .date-title {
    color: #4141b1;
    text-align: left;
}

.Placeholder h1 {
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
}

.Placeholder .intro-wrapper {
    display: flex;
    flex-direction: row;
}

.Placeholder .intro-text {
    text-align: left;
    /* padding: 20px; */
}

.Placeholder .intro-icon img {
    width: 80%;
}

.Placeholder .intro-image {
    margin: 0 20px 0 0;
}
.Placeholder .intro-image img {
   width: 100px;
    
}

.Placeholder .intro-icons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.Placeholder .intro-subtitle {
    font-weight: bold;
}

.Placeholder .dates-wrapper {
    display: flex;
    flex-direction: column;
    
}

.Placeholder .date-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    
}



.Placeholder .date-title {
    margin-right: 20px;
}

.pfooter {
    background-color: rgb(112, 223, 213);
    position: fixed;
    bottom: 0;
    width: 100%;
    
    color: white;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 500px) {
    .Placeholder h1 {
        font-size: 22px;
    }
}