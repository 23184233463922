.RegisterRedirect {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.RegisterRedirect button {
    width: 90%;
    margin-top: 10px;
    height: 50px;
}