.Leaderboards {
    /* margin-top: 53px; */
    /* margin-bottom: 150px; */
    /* max-height: 90vh; */
    /* background: linear-gradient(#e9a3f5, #8161da); */
    
    width: 90%;
    
    
    
    position: relative;
}

.leaderboard-text {
    padding: 15px;
    /* font-weight: 200; */
    /* color: white; */
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin: auto;
    
}

.Leaderboards .heading-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e0e0e0;
}

.noLeaderboards {
    color: white;
}

.board {
    /* width: 90vw; */
    align-self: center;
    margin-left: auto;
    margin-right: auto;
    background: white;
    box-shadow: 0px -10px 40px #fff;
}

.tab {
    overflow: hidden;
    /* background-color: rgb(1, 171, 181); */
    background-color: white;
    display: flex;
    justify-content: center;
    position: sticky;
    top: 53px;
    z-index: 3;
    margin-top: 10px;
    margin-bottom: 10px;
}

.tab2 {
    top: 93px;
    justify-content: space-around;
    /* background-color: rgb(68, 33, 97); */
    background-color: white;
}

.tab button {
    background-color: inherit;
    color: #0f0f0f;
    font-weight: bold;
    border: thin solid #0f0f0f;
    outline: none;
    cursor: pointer;
    width: 40vw;
    height: 50px;
    margin: 5px;
    transition: 0.3s;
    border-radius: 30px;
}

.tab button:hover {
    /* background-color: rgb(237, 19, 120); */
    color: #000000
}

.tab button.active {
    /* background-color: rgb(237, 19, 120); */
    color: white;
    background-color: #000000;
    
}

.tab2 button:hover {
    color: rgb(231, 108, 37);
}

.tab2 button.active {
    background-color: rgb(231, 108, 37);
    color: white;
}

@media (min-width: 576px) {
    .Leaderboards {
        max-width: 500px;
        /* width: 0; */
    }
}
