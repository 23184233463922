.Setting {
    /* margin-top: 52px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: thin solid #eee;
    padding: 20px;
}

.Setting .label {
    
}