
.Challenge .header {
    border-radius: 50%;
    /* width: 95%; */
    padding: 50%;
    box-sizing: border-box;
    /* position: absolute; */
    /* top: 0%; */
    margin: -60% auto 0 auto;
    display: flex;
    /* left: 50%; */
    justify-content: center;
}

.Challenge .textWrapper {
    /* display: flex; */
    width: 100%;
    position: absolute;
    max-width: 242px;
    top: 140px;
    flex-direction: column;
    padding: 20px;
    /* left: 50%; */
}

.Challenge .Text, .Challenge .subText {
    color: rgb(255, 255, 255);
    width: 100%;
    /* position: absolute; */
    left: 0;
    top: 45px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    left: -50%;
}

.Challenge .subText {
    text-transform: capitalize;
    top: 80px;
    /* padding: 0 45px; */
    font-weight: 500;
    
}

/* .Challenge .header {
    color: white;
    text-transform: uppercase;
    flex-direction: row;
    padding: 0 0 10px 0;
    font-weight: bold;
    text-align: center;
} */

@media only screen and (min-width: 500px) {
    .Challenge .Text {
        
        top: 5px;
       
    }
    
    .Challenge .subText {
        top: 120px;
        
        
    }

    .Challenge .textWrapper {
        
        max-width: 300px;
    }
}