.Login {
    
    /* margin-top: 80px; */
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100%;
    
}

.Login form {
    /* height: 50%; */
}

.Login input {
    width: 90%;
    height: 50px;
    border: none;
    border-bottom: 1px solid #dddddd;
    border-radius: 0;
}

.Login input[type=password] {
    font-family: inherit !important;
}

.Login .p-inputtext:enabled:focus {
    border-bottom-color: #4c3494 !important;
}

.Login .p-button {
    width: 90%;
    height: 50px;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 30px;
    
    
}

.Login .logo-wrapper {

    /* width: 100%;
    height: 60%; */
    /* background-color: red; */
    
}

.Login .form-wrapper {
    height: 50%;
}

.Login .alt-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 10px;
}

.Login .iforgot {
    text-transform: uppercase;
    cursor: pointer;
}

.Login .register {
    text-transform: uppercase;
    cursor: pointer;
}
/* .Login .logo-wrapper .animain {
    position: relative;
}
.Login .logo-wrapper .animain .screen {
    position: relative;
    height: 100%;
} */