.Team {
    /* display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-top: thin solid lightgrey; */
}
 .Team .flag {
     width: 30px;
     margin-right: 5px;
 }
.Team .teamPanel {
    width: 100%;
}

.Team .teamPanel .p-panel-titlebar{
    background-color: white;
    border: none;
    color: lightgray;
    padding: 20px;
    
    
}

.Team .teamPanel .p-panel-titlebar .p-panel-title {
    font-weight: 200;
    color: black;
}

.Team .teamPanel .p-panel-titlebar .p-panel-titlebar-icon{
    
    color: #01abb5;
    
    
}

.Team .teamPanel .p-panel-titlebar .p-panel-titlebar-icon:hover, 
.Team .teamPanel .p-panel-titlebar .p-panel-titlebar-icon:active,
.Team .teamPanel .p-panel-titlebar .p-panel-titlebar-icon:focus {
    color: inherit !important;
    border-color: none;
    box-shadow: none;
}

.Team .member {
    list-style: none;
    padding: 0;
}

.Team ul {
    padding: 0;
    text-align: left;
}
