.Loader .overlay {
    height: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: black;
    opacity: 0.4;
    z-index: 100;
    left: 0;
    top: 0;
    
}

.Loader .text {
    z-index: 12;
    opacity: 1;
    color: white;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    width: 100%;
    height: 100%;
    left: 0;
}