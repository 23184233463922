nav {
    width: 100%;
    position: fixed;
    bottom: 0;
    /* border-top: thin solid; */
    background-color: #e0e0e0;
    /* color: #010101; */
    height: 60px;
    font-size: 16px;
    z-index: 12;
    /* padding-bottom: 60px; */
    padding-top: 10px;
    font-weight: bold;
}

nav ul {
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   align-items: center;
   padding: 0;
   list-style: none;
   
}



nav ul li a{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #010101;
   
}

nav ul li a.active, nav ul li a:hover{
    color: #010101;
}

nav ul li i {
   font-size: 0.6em;
}

@media (min-width: 576px) {

    nav {
        width: 0;
        min-width: 500px;
    }
}
