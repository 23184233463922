.Forgot.hide {
    transform: translateY(100vh);
    transition: transform 0.5s;
}

.Forgot.show {
    transform: translateY(none);
    transition: transform 0.5s;
}

.Forgot {
    height: 100vh;
    width: 100%;
    background-color: white;
    position: fixed;
    top: 0;
    overflow: scroll;
    z-index: 999;

}

.Forgot input {
    width: 90%;
    height: 50px;
    border: none;
    border-bottom: 1px solid #dddddd;
    border-radius: 0;
}

.Forgot .title {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: right;
    align-items: right;
    position: fixed;
    
}

.Forgot .emailWrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.Forgot .emailWrapper p.recoverText {
    font-size: 1.5em;
    font-weight: 100;
    
}

.Forgot .emailWrapper .buttons {
    margin-top: 10px;
}

.Forgot .shake {
    animation: shake 0.8s;
}

@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }