/* 
.Register {
    
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    
}

.Register form {
    height: 60%;
}

.Register input {
    width: 90%;
    height: 50px;
    border: none;
    border-bottom: 1px solid #dddddd;
    border-radius: 0;
}

.Register input[type=password] {
    font-family: inherit !important;
}

.Register .p-inputtext:enabled:focus {
    border-bottom-color: #5DC2A7 !important;
}

.Register .p-button {
    width: 90%;
    height: 50px;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 30px;
    
}

.Register .logo-wrapper {

    width: 100%;
    height: 40%;
    
}

.Register .logo-wrapper .animain {
    position: relative;
}
.Register .logo-wrapper .animain .screen {
    position: relative;
    height: 100%;
} */

.Register {
    
    /* margin-top: 80px; */
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100%;
    
}

.Register form {
    /* height: 50%; */
}

.Register input {
    width: 90%;
    height: 50px;
    border: none;
    border-bottom: 1px solid #dddddd;
    border-radius: 0;
}

.Register input[type=password] {
    font-family: inherit !important;
}

.Register .p-inputtext:enabled:focus {
    border-bottom-color: #4c3494 !important;
}

.Register .p-button {
    width: 90%;
    height: 50px;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 30px;
    background-color: #4c3494;
    
}

.Register .logo-wrapper {

    width: 100%;
    height: 50%;
    /* background-color: red; */
    
}

.Register .form-wrapper {
    height: 50%;
    z-index: 999;
    overflow: scroll;
}

.Register .acceptTermsWrapper {
    /* width: 100%; */
    /* display: flex; */
    /* flex-direction: row-reverse; */
    align-items: center;
    /* justify-content: flex-start; */
    padding: 0 25px;
    color: #666666;
}

.Register .acceptTermsWrapper #acceptTerms {
    margin: 0 10px;
}

@media (min-width: 768px) {
    .Register {
    
        /* margin-top: 80px; */
        display: flex;
        flex-direction: row;
        /* position: fixed; */
        width: 100%;
        height: 100%;
        align-items: center;
        
    }

    .Register .logo-wrapper {

        width: 50%;
        height: 50%;


        
    }

    .Register .form-wrapper {
        /* height: 50%;
        z-index: 999;
        overflow: scroll; */
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        
    }

    .Register form {
        /* height: 50%; */
        width: 100%;
    }
}

