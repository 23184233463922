.Leaderboard {
    /* font-weight: 200; */
    
}

.Leaderboard .entry {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 5px 5px 0;
    align-items: center;
    border-bottom: #eee thin solid;
    color: #0f0f0f;
}

.Leaderboard .entry .cell {
    z-index: 1;
}
.Leaderboard .entry .cell.avatar {
    /* width: 16vw; */
    color: black;
    justify-content: center;
    align-items: center;
    display: flex;
}
.Leaderboard .entry .cell.name {
    
}
.Leaderboard .entry .cell.points {
    /* width: 25vw; */
    font-size: 1.5em;
}

.Leaderboard .avatar {
    width: 50px;
    height: 50px;
    clip-path: circle(25px at center);
    object-fit: cover;
    left: 0;
}

.Leaderboard .avatar img {
    width: 50px;
    height: 50px;
    clip-path: circle(25px at center);
    object-fit: cover;
}

.progressbar {
    position: absolute;
    top: 5px;
    bottom: 5px;
    /* left: 15vw; */
    border-radius: 30px;
    /* background-color: #C0D95E;b5ce55 */
    background-color: #b5ce55;
    /* height: 40px; */
    height: 50px;
    
}

.isMe {
    position: absolute;
    top: 5px;
    bottom: 5px;
    /* left: 15vw; */
    border-radius: 30px;
    background-color: #FC844C;
    height: 50px;
    
}

.empty-progressbar {
    position: absolute;
    top: 5px;
    bottom: 5px;
    /* left: 15vw; */
    border-radius: 30px;
    /* background-color: #dfdfdf; */
    background-color: #e0e0e0;
    height: 50px;
    
}
