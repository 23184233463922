body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.p-button-primary {
  background-color: #01abb5 !important;
    color:white !important;
    border: #01abb5 thin solid !important;
    border-radius: 5px !important;
}

.p-button-secondary {
  border: white solid thin !important;
  color: white !important;
}

.p-button-danger {
  border: none !important;
  background-color: transparent !important;
  color: #DB4436 !important;
  text-transform: uppercase !important;
  font-weight: bold;
}

.p-button-warning {
  border: none !important;
  background-color: transparent !important;
  color: darkorange !important;
  text-transform: uppercase !important;
  font-weight: bold;
}

.p-button-info {
  border: none !important;
  background-color: transparent !important;
  color: #4c3494 !important;
  text-transform: uppercase !important;
  font-weight: bold;


}

.p-button-success {
  width: 90%;
  height: 50px;
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 30px;
  background-color: #4c3494 !important;
    border-color: #4c3494 !important;
  /* background-color: white !important; */
  
}

a:hover {
  text-decoration: none !important;
}

input {
  width: 80%;
  height: 50px;
  border-radius: 5px;
}

input:focus,
select:focus,
textarea:focus,
button:focus,
.p-button:focus,
.p-dialog-titlebar-icon:focus,
.p-dialog-titlebar-close-icon:focus
 {
    outline: none !important;
    box-shadow: none !important;
}

.p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon {
  color: #6D7173 !important;
}

.p-inputtext {
  width: 90% !important;
}

.p-inputtext:enabled:hover {
  border-color: #dddddd !important;
}

.p-inputtext:enabled:focus {
  border-color: #dddddd !important;
  outline: none;
  box-shadow: none !important;
}

.p-dialog .p-dialog-content{
  border: none !important;
}

.p-dialog .p-dialog-footer{
  border: none !important;
}

.p-dialog .p-dialog-titlebar {
  /* background-color: #01abb5 !important;
  border: 1px solid #01abb5 !important; */
  color: #6D7173 !important;
  background-color: white !important;
  border: none !important;
}

.react-confirm-alert-body {
  border-radius: 0px !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif !important;
  font-weight: 100;
}

.react-confirm-alert-body h1 {
  font-size: 1.5em !important;
}

.react-confirm-alert-button-group button {
  border-radius: 0px !important;
  background: none !important;
  color: #5DC2A7 !important;
  text-transform: uppercase !important;
}

.green {
  background-color: #5DC2A7 !important;
  color: white;
  border: none;
}

svg {
  overflow: visible !important;
}