.MyTeamDetails {
    margin-top: 52px;
}

.MyTeamDetails .fileUpload {
    display: flex;
    justify-content: center;


}
.MyTeamDetails .title {
    font-weight: bold;
    color: #6D7173 !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    background-color: #F9F9F9;
}

.MyTeamDetails .team-memberlist {
    text-decoration: none;
    list-style: none;
    text-align: left;
    padding: 0;
    
}

.MyTeamDetails .team-memberlist li {
    /* margin-left: 30px; */
}

.MyTeamDetails .title button {
    border: none !important;
    background-color: transparent !important;
    color: #6D7173 !important;
}

.MyTeamDetails .avatar-thumb i {

    width:140px;
    height: 140px;
    clip-path: circle(70px at center);
    font-size: 4.4em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
}

.MyTeamDetails .avatar-thumb {
    width: 80px;
    margin-right: 10px;
    cursor: pointer;
   
}

.MyTeamDetails .avatar-thumb img {

    width: 140px;
    height: 140px;
    clip-path: circle(70px at center);
    object-fit: cover;
}

