.TeamsList.hide {
    transform: translateY(100vh);
    transition: transform 0.5s;
}

.TeamsList.show {
    transform: translateY(none);
    transition: transform 0.5s;
}

.TeamsList {
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: white;
    position: fixed;
    top: 0;
    overflow: scroll;
    z-index: 999;
    transform: translateY(0);
    transition: transform 0.5s;
    padding-bottom: 60px;
    
}
.TeamsList .list{
    
    
    position: relative;
    margin-bottom: 150px;
    margin-top: 50px;
    /* height: 100%; */
}

.TeamsList .title {
    font-weight: bold;
    color: #6D7173;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #F9F9F9;
    position: fixed;
    width: 100%;
    z-index: 3;
    
}



.TeamsList .title button {
    border: none !important;
    background-color: transparent !important;
}