.Share .message {
    font-size: 1.2em;
    color: red;
    text-transform: uppercase;
}

.Share .buttonWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
}