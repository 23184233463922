.Layout {
    margin-top: 100px;
    max-width: 500px;

}

@media (min-width: 576px) {
    .Layout {
        min-width: 500px;
        /* text-align: center; */
    }

}