.AnimatedLogo {
  /* background-color: red; */
  height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.AnimatedLogo #head {
  -webkit-animation: moveHead 0.85s ease-in-out infinite alternate;
  animation: moveHead 0.85s ease-in-out infinite alternate;
}

.AnimatedLogo #hands {
  transform-origin: 10% 35%;
  -webkit-animation: rotateHands 0.85s ease-in-out infinite alternate;
  animation: rotateHands 0.85s ease-in-out infinite alternate;
}

.AnimatedLogo #shin {
  transform-origin: 15% 60%;
  webkit-animation: rotateShin 0.85s ease-in-out infinite alternate;
  animation: rotateShin 0.85s ease-in-out infinite alternate;
}

.AnimatedLogo #body {
  transform-origin: 10% 50%;
  webkit-animation: rotateBody 0.85s ease-in-out infinite alternate;
  animation: rotateBody 0.85s ease-in-out infinite alternate;
}

.AnimatedLogo #person {
  /* position: absolute; */
  top: 37%;
  left: 60%;
  width: 60%;
  -webkit-animation: movePerson 0.85s ease-in-out infinite alternate;
  animation: movePerson 0.85s ease-in-out infinite alternate;

}

.AnimatedLogo #shadow {
  /* position: absolute; */
  top: 52%;
  left: 79%;
  -webkit-animation: pulse 0.85s ease infinite alternate;
  -moz-animation: pulse 0.85s ease infinite alternate;
  -o-animation: pulse 0.85s ease infinite alternate;
  animation: pulse 0.85s ease infinite alternate;
}

.AnimatedLogo #_1 {
  animation-fill-mode: backwards;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-iteration-count: initial;
  -webkit-animation-iteration-count: initial;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: linear;
}
.AnimatedLogo #_2 {
  animation-fill-mode: backwards;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-iteration-count: initial;
  -webkit-animation-iteration-count: initial;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-timing-function: linear;
  animation-delay: 0.5s;
}
.AnimatedLogo #_3 {
  animation-fill-mode: backwards;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-iteration-count: initial;
  -webkit-animation-iteration-count: initial;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: linear;

  animation-delay: 1s;
}

.AnimatedLogo svg {
  overflow: visible;
  margin: 0;
  position: absolute;
  top: 45%;
  left: 60%;
  transform: translate(-50%, -50%);
  width: 75%;
}

@-webkit-keyframes pulse {
  0% {
    width: 6.5em;
  }
  100% {
    width: 6em;
  }
}
@-moz-keyframes pulse {
  0% {
    width: 6.5em;
  }
  100% {
    width: 6em;
  }
}
@-o-keyframes pulse {
  0% {
    width: 6.5em;
  }
  100% {
    width: 6em;
  }
}
@keyframes pulse {
  0% {
    width: 6.5em;
  }
  100% {
    width: 6em;
  }
}

@-webkit-keyframes moveHead {
  0% {
    -webkit-transform: translate(-110px, -180px);
    -moz-transform: translate(-110px, -180px);
    -ms-transform: translate(-110px, -180px);
  }

  100% {
    -webkit-transform: translate(-200px, -210px);
    -moz-transform: translate(-200px, -210px);
    -ms-transform: translate(-200px, -210px);
  }
}

@-webkit-keyframes movePerson {
  0% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
  }

  100% {
    -webkit-transform: translate(0, -30px);
    -moz-transform: translate(0, -30px);
    -ms-transform: translate(0, -30px);
  }
}

@-webkit-keyframes rotateHands {
  0% {
    -webkit-transform: rotate(0deg) translate(-120px, -190px);
    transform: rotate(0deg) translate(-120px, -190px);
  }
  100% {
    -webkit-transform: rotate(-190deg) translate(-170px, -110px);
    transform: rotate(-190deg) translate(-170px, -110px);
  }
}
@keyframes rotateHands {
  0% {
    -webkit-transform: rotate(0deg) translate(-120px, -190px);
    transform: rotate(0deg) translate(-120px, -190px);
  }
  100% {
    -webkit-transform: rotate(-190deg) translate(-170px, -110px);
    transform: rotate(-190deg) translate(-170px, -110px);
  }
}

@-webkit-keyframes rotateShin {
  0% {
    -webkit-transform: rotate(-30deg) translate(-130px, -190px);
    transform: rotate(-30deg) translate(-130px, -190px);
  }
  100% {
    -webkit-transform: rotate(0deg) translate(-125px, -190px);
    transform: rotate(0deg) translate(-125px, -190px);
  }
}
@keyframes rotateShin {
  0% {
    -webkit-transform: rotate(-30deg) translate(-130px, -190px);
    transform: rotate(-30deg) translate(-130px, -190px);
  }
  100% {
    -webkit-transform: rotate(0deg) translate(-125px, -190px);
    transform: rotate(0deg) translate(-125px, -190px);
  }
}

@-webkit-keyframes rotateBody {
  0% {
    -webkit-transform: rotate(0deg) translate(-120px, -200px);
    transform: rotate(0deg) translate(-120px, -200px);
  }
  100% {
    -webkit-transform: rotate(-30deg) translate(-110px, -200px);
    transform: rotate(-30deg) translate(-110px, -200px);
  }
}
@keyframes rotateBody {
  0% {
    -webkit-transform: rotate(0deg) translate(-120px, -200px);
    transform: rotate(0deg) translate(-120px, -200px);
  }
  100% {
    -webkit-transform: rotate(-30deg) translate(-110px, -200px);
    transform: rotate(-30deg) translate(-110px, -200px);
  }
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}