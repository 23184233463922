.TeamMember .avatar-thumb i {

    width: 80px;
    height: 80px;
    clip-path: circle(40px at center);
    font-size: 4.4em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
}

.TeamMember .avatar-thumb {
    width: 80px;
    margin-right: 10px;
    
   
}

.TeamMember .avatar-thumb img {

    width: 80px;
    height: 80px;
    clip-path: circle(40px at center);
    object-fit: cover;
}


/* .TeamMember i {
    margin-right: 10px;
    border: 1px solid;
    background-color: #F9F9F9;
    border-radius: 20px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: #D7D7D7;
    
} */
.TeamMember {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #808080;
    padding: 10px 20px;
    border-bottom: 1px solid;
    border-color: #D7D7D7;
    width: 100%;
    
}

.TeamMember:hover {
    background-color: #F2F9F7;
}

.TeamMember .points {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    font-size: 2em;
}

.TeamMember .name {
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
}