.NoTeam {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* position: absolute; */
    /* width: 100%; */
    margin-left: 40px;
    margin-right: 40px;
    min-height: 400px;
}

.NoTeam .AddButton {
    position: absolute;
    bottom: 80px;
    right: 20px;
}

.NoTeam .AddJoinPanel {
    background-color: #4c3494;
    width: 100%;
    position: fixed;
    bottom: 50px;
    height: 120px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 50px;
    color: white;
    font-weight: bold;
    
}

.NoTeam .AddJoinPanel a.white i {
    color: #6D7173 !important;
}


.btn-floating {
    display: inline-block;
    color: #fff;
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    background-color: #4c3494;
    border-radius: 50%;
    -webkit-transition: background-color .3s;
    transition: background-color .3s;
    cursor: pointer;
    vertical-align: middle;
  }

  .btn-floating.btn-large {
    width: 56px;
    height: 56px;
    padding: 0;
  }
  
  .btn-floating.btn-large.halfway-fab {
    bottom: -28px;
  }
  
  .btn-floating.btn-large i {
    line-height: 56px;
  }

  .white {
    background-color: #FFFFFF !important;
  }

  .z-depth-1, nav, .card-panel, .card, .toast, .btn, .btn-large, .btn-small, .btn-floating, .dropdown-content, .collapsible, .sidenav {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }
  
  .z-depth-1-half, .btn:hover, .btn-large:hover, .btn-small:hover, .btn-floating:hover {
    -webkit-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
            box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
  }